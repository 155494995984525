<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <div class="d-flex">
        <span>{{ $lang.labels.language }}:&nbsp;&nbsp;</span>
        <span>{{ $lang.labels.dynamic }}</span>
        <v-switch v-model="ocrStatic" dense class="ml-1 mr-0 my-0"/>
        <span>{{ $lang.labels.static }}</span>
      </div>
    </v-col>
    <v-col v-if="ocrStatic && !lock" cols="12">
      <v-autocomplete
        :key="`${step.id}-ocrStatic-language`"
        v-model="properties.language"
        data-cy="ocr-language"
        outlined
        dense
        :items="formattedOcrLanguages"
        clearable
        hide-no-data
        :label="$lang.labels.language"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        @change="handleChange('language', $event)"
      />
    </v-col>

    <v-col v-if="!ocrStatic && !lock" cols="12">
      <v-text-field
        :key="`${step.id}-language`"
        v-model="properties.language"
        outlined
        dense
        :label="$lang.labels.language"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ocr-language"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-image`"
        v-model="properties.image"
        outlined
        dense
        :label="$lang.labels.image"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ocr-image"
        @change="handleChange('image', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ocr-targetObject"
      />
    </v-col>
  </v-row>
</template>

<script>
import StepMixin from './StepMixin'
import { SUPPORTED_LANGUAGES } from '@/utils/constants'
import { castValueForStep } from '@/utils/helpers'

export default {
  name: 'OCRStep',
  mixins: [StepMixin],
  props: {
    lock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ocrLanguages: SUPPORTED_LANGUAGES.sort(),
      ocrStatic: true
    }
  },
  computed: {
    formattedOcrLanguages() {
      return this.ocrLanguages
        .map((x) => {
          return { text: this.$lang.labels[x], value: x }
        })
        .sort()
    }
  },
  watch: {
    ocrStatic: {
      handler() {
        if (!this.lock) {
          this.properties.language = ''
        }
      }
    }
  },
  created() {
    if (!this.properties.language) this.properties.language = 'en'
    if (this.properties.language && SUPPORTED_LANGUAGES.indexOf(this.properties.language) === -1) {
      this.ocrStatic = false
    }
  },
  methods: {
    handleChange(key, value) {
      this.$set(this.properties, key, castValueForStep(
        key,
        value,
        {
          shouldBeString: ['language', 'image']
        }
      ))
    }
  }
}
</script>
